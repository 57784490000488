import React from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { ErrorMessage, Field, useField } from "formik";

export const Phone = ({ className, label, name, placeholder, required }) => {
  const [field, meta, helpers] = useField({ name });

  const {
    inputRef,
    inputValue, // +60 12-3456-78
    phone, // +6012345678
    country,
    setCountry,
    handlePhoneValueChange,
  } = usePhoneInput({
    defaultCountry: "my",
    value: "",
    countries: defaultCountries,
    onChange: ({ phone, inputValue, country }) => {
      const _phone = phone.replace(`+${country.dialCode}`, "");
      helpers.setValue(_phone > 0 ? phone : _phone);
    },
  });

  return (
    <div className={`${className} relative flex flex-col gap-2 p-2`}>
      {label && (
        <label htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <TextField
        inputRef={inputRef}
        id={name}
        name={name}
        type="tel"
        variant="outlined"
        color="primary"
        placeholder={placeholder}
        value={inputValue}
        onChange={handlePhoneValueChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                MenuProps={{
                  style: {
                    height: "100%",
                    maxHeight: "300px",
                    width: "100%",
                    top: 6,
                    left: -40,
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  fieldset: {
                    display: "none",
                  },
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => (
                  <FlagImage
                    iso2={value}
                    style={{ display: "flex", width: "20px", height: "auto" }}
                  />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{
                          marginRight: "8px",
                          width: "20px",
                          height: "auto",
                        }}
                      />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
          sx: {
            marginY: "-4px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& input:focus": {
              outline: "none !important",
              border: "none !important",
              boxShadow: "none !important",
            },
          },
        }}
        sx={{
          borderRadius: "8px",
          background: meta.touched && meta.error ? "#fdf2f2" : "#F9FAFB",
          border:
            meta.touched && meta.error
              ? "1px solid #e02424"
              : "1px solid #D1D5DB",
          ".MuiInputBase-input": {
            paddingTop: "12px!important",
            paddingBottom: "12px!important",
          },
        }}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
