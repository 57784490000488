import ApiInstance from "./ApiInstances";
import API_ENDPOINTS from "./ApiRoutes";

export const getHeaderFooter = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_HEADER_FOOTER}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getHome = async (slug) => {
  try {
    const response = await ApiInstance.get(`/${slug}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProducts = async (slug, params = "") => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_PRODUCTS}${params}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProductDetails = async (slug, id) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_PRODUCTS}/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFormFields = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_FORM_FIELDS}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFormProducts = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_FORM_PRODUCTS}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBuyerGuides = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_BUYER_GUIDES}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStores = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_STORES}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getContactUs = async (slug) => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_CONTACT_US}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTrack = async (slug, params = "") => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_TRACK}${params}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFormStores = async (slug, params = "") => {
  try {
    const response = await ApiInstance.get(
      `/${slug}${API_ENDPOINTS.GET_FORM_STORES}${params}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postSubmit = async (slug, formData) => {
  try {
    const response = await ApiInstance.post(
      `/${slug}${API_ENDPOINTS.POST_SUBMIT}`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postDraw = async (slug, body) => {
  try {
    const response = await ApiInstance.post(
      `/${slug}${API_ENDPOINTS.POST_DRAW}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
