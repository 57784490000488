import { Link } from "react-router-dom";

export const LinkButton = ({
  primary,
  secondary,
  className,
  label,
  link = "#",
}) => {
  return (
    <div>
      <Link
        className={`py-3 px-5 rounded-lg flex max-w-fit ${
          primary ? "bg-primary-700 text-white" : ""
        } ${secondary ? "bg-gray-200 text-gray-900" : ""} ${className}`}
        to={link}
      >
        {label}
      </Link>
    </div>
  );
};
