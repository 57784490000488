import { ErrorMessage, Field, useField } from "formik";
import React from "react";

export const TextInput = ({
  className,
  label,
  name,
  type,
  placeholder,
  required,
  isOnlyAlphanumeric,
}) => {
  const [field, meta, helpers] = useField({ name });

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {label ? (
        <label htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      ) : null}
      <Field
        id={name}
        name={name}
        type={type}
        onChange={(e) => {
          const regex = /^[a-zA-Z0-9]*$/;
          if (isOnlyAlphanumeric && !regex.test(e.target.value)) return;
          helpers.setValue(e.target.value);
        }}
        placeholder={placeholder}
        className={`px-3 py-2 rounded-lg border text-gray-800 focus:outline-none focus:border-blue-500 ${
          meta.touched && meta.error
            ? "border-red-600 bg-red-50"
            : "border-gray-300 bg-gray-50"
        }`}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
