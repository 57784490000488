const API_ENDPOINTS = {
  GET_HEADER_FOOTER: `/headers-footers`,
  GET_PRODUCTS: `/products`,
  GET_FORM_FIELDS: `/form-fields`,
  GET_FORM_PRODUCTS: `/form-products`,
  GET_BUYER_GUIDES: `/buyers-guide`,
  GET_STORES: `/stores`,
  GET_CONTACT_US: `/contact-us`,
  GET_TRACK: `/track`,
  GET_FORM_STORES: `/form-stores`,
  POST_SUBMIT: `/submission`,
  POST_DRAW: `/draw`,
};

export default API_ENDPOINTS;
