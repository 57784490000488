export const Button = ({
  primary,
  secondary,
  className,
  label,
  onClick = () => {},
  disabled,
  type = "button",
}) => {
  return (
    <button
      className={`py-3 px-5 rounded-lg text-body font-medium ${
        primary ? "bg-primary-700 text-white" : ""
      } ${
        secondary ? "bg-gray-200 text-gray-900" : ""
      } disabled:bg-gray-200 disabled:text-white ${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
