import React from "react";

export const getPath = (slug, path = "") => {
  return `/${slug}${path}`;
};

export const isNotNull = (val) => {
  return val !== null && val !== undefined && val !== "" && val !== "null";
};

export const renderTextAreaContent = (val) => {
  return val.split("\r\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};
